import './src/styles/global.css';
import '@splidejs/react-splide/css';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    {element}
  </QueryClientProvider>
);
